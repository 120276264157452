<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{
        $t("ReporDriverRoutes.page_title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- New marker speed config, moved after v-spacer to align right -->
      <v-select
        v-model="markerSpeed"
        :items="Object.values($t('ReporDriverRoutes.markerSpeedOptionsArray'))"
        :label="$t('ReporDriverRoutes.markerSpeedSelect_label')"
        dense
        hide-details
        style="max-width: 150px"
        class="mx-4"
      ></v-select>
      <v-icon x-large>mdi-calendar-clock</v-icon>
    </v-toolbar>
    <!-- <v-row justify="end">
      <v-spacer></v-spacer>
      <v-col v-show="$vuetify.breakpoint.smAndDown" cols="12" md="3">
        <v-switch
          v-model="mobileBreakpointSwitch"
          :label="$t('ReporDriverRoutes.mobileBreakpointSwitch_label')"
          hide-details
        ></v-switch>
      </v-col>
    </v-row> -->

    <v-row class="my-2" align="center">
      <v-col cols="12" md="4">
        <autocomplete-driver-remote
          v-model="driverId"
          :label="$t('ReporDriverRoutes.btnAutocompleteDriverRemote')"
          :placeholder="
            $t('ReporDriverRoutes.btnAutocompleteDriverRemote_placeholder')
          "
          :title="$t('ReporDriverRoutes.btnAutocompleteDriverRemote')"
          :searchParams="driverSearchData"
          dense
          singleLine
          hideDetails
        ></autocomplete-driver-remote
      ></v-col>
      <v-col cols="12" md="3">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDate"
              :label="$t('ReporDriverRoutes.dateSelector')"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDate"
            no-title
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <v-row align="center">
          <v-btn
            small
            fab
            dark
            class="ma-2"
            color="info"
            @click="decreaseHour"
            :loading="loadingDataMinus"
            :readonly="loadingData"
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
          <v-menu
            v-model="hourMenu"
            :close-on-content-click="false"
            offset-y
            max-width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedHour"
                :label="$t('ReporDriverRoutes.hourSelector')"
                readonly
                style="max-width: 80px"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <div class="hour-panel">
              <v-btn
                v-for="hour in hourOptions"
                :key="hour"
                :color="selectedHour === hour ? 'primary' : 'default'"
                @click="selectHourFromPopup(hour)"
                small
              >
                {{ hour }}
              </v-btn>
            </div>
          </v-menu>
          <v-btn
            small
            fab
            dark
            class="ma-2"
            color="info"
            @click="increaseHour"
            :loading="loadingDataPlus"
            :readonly="loadingData"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          color="info"
          large
          @click="updateData()"
          :loading="loadingDataUpdate"
          :readonly="loadingData"
        >
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col></v-row
    >
    <div id="map"></div>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<style scoped>
#map {
  height: 65vh;
}
.hour-panel {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4px;
  padding: 8px;
  background-color: #999;
}
</style>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import {
  Status,
  Type,
  Source,
  PaymentType,
  PaymentTypeColor,
  StatusColor,
  StatusColorDarkContrast,
  TypeColor,
  SourceColor,
} from "../enums/OrderEnum";
import AutocompleteDriverRemote from "../components/AutocompleteDriverRemote.vue";
//import OrdersGoogleMap from "../components/OrdersGoogleMap.vue";

export default {
  components: {
    AutocompleteDriverRemote,
    //OrdersGoogleMap,
  },
  data() {
    return {
      module: moduleDefaultSettings,
      Status,
      Type,
      Source,
      PaymentType,
      PaymentTypeColor,
      StatusColor,
      StatusColorDarkContrast,
      TypeColor,
      SourceColor,
      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      //ID taxisluzby a dispecera
      dispatcherId: null,
      taxiserviceId: null,
      //Zoznam vodicov
      driverId: null,
      driverSearchData: {
        query: null,
        type: null,
      },

      loadingData: false,
      loadingDataUpdate: false,
      loadingDataPlus: false,
      loadingDataMinus: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      passDataOnMap: [],
      reducePositions: true,
      positions: [],
      map: null,
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedHour: 12,
      hourOptions: Array.from(Array(24).keys()), // [0..23]
      dateMenu: false,
      driverMarkers: [],
      driverPolyline: null,
      driverPolylines: [], // added for storing polylines
      driverRoads: [],
      driverId: localStorage.getItem("selectedDriverId") || 0, // sample driver ID
      hourMenu: false,
      // New marker speed configuration
      markerSpeed: "fast",
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
  },

  mounted() {
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //console.log("dispatcherId", dispatcherId);
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");

    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }
    //set this.selectedHour to current hour
    this.selectedHour = new Date().getHours();

    this.initMap();
    setTimeout(() => {
      this.getAll();
    }, 2000);
  },

  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  computed: {
    markerDelay() {
      switch (this.markerSpeed) {
        case "none":
          return 1;
        case "fast":
          return 20;
        case "medium":
          return 50;
        case "slow":
          return 100;
        case "snail":
          return 200;
        default:
          return 20;
      }
    },
  },

  methods: {
    initMap() {
      if (!window.google) return;
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 48.15, lng: 18.11 },
        zoom: 8,
        maxZoom: 17,
        minZoom: 7,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapId: "f1b7b3b3b3b3b3b3",
        streetViewControl: false,
        fullscreenControl: true,
        scaleControl: false,
      });
    },

    increaseHour() {
      //if more than 23 hours, increase selectedDate
      if (this.selectedHour < 23) {
        this.selectedHour++;
      } else {
        var selectedDate = new Date(this.selectedDate);
        selectedDate.setDate(selectedDate.getDate() + 1);
        this.selectedDate = selectedDate.toISOString().substr(0, 10);
        this.selectedHour = 0;
      }
      this.loadingDataPlus = true;
      this.getAll();
    },
    decreaseHour() {
      //if less than 0 hours, decrease selectedDate
      if (this.selectedHour > 0) {
        this.selectedHour--;
      } else {
        var selectedDate = new Date(this.selectedDate);
        selectedDate.setDate(selectedDate.getDate() - 1);
        this.selectedDate = selectedDate.toISOString().substr(0, 10);
        this.selectedHour = 23;
      }
      this.loadingDataMinus = true;
      this.getAll();
    },
    updateData() {
      this.loadingDataUpdate = true;
      this.getAll();
    },

    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.positions = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        this.loadingDataUpdate = false;
        this.loadingDataPlus = false;
        this.loadingDataMinus = false;
        return;
      }
      if (this.driverId == null || this.driverId == 0) {
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t(
          "ReporDriverRoutes.msgDriverNotSelected"
        );
        this.snackbar.color = "warning";
        this.loadingDataUpdate = false;
        this.loadingDataPlus = false;
        this.loadingDataMinus = false;
        return;
      }

      if (!this.selectedDate || this.selectedHour == null) {
        //snackbar
        this.snackbar.show = true;
        //this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.message = this.$t(
          "ReporDriverRoutes.msgDateAndTimeNotSelected"
        );
        this.snackbar.color = "warning";
        this.loadingDataUpdate = false;
        this.loadingDataPlus = false;
        this.loadingDataMinus = false;
        return;
      }
      var selectedDate = new Date(this.selectedDate);
      //console.log("selectedDate", selectedDate);
      var fromTime = new Date(selectedDate);
      fromTime.setFullYear(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      fromTime.setHours(this.selectedHour, 0, 0, 0);
      fromTime = fromTime.toISOString();
      var toTime = new Date(selectedDate);
      toTime.setFullYear(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      //set time at the end of the hour
      toTime.setHours(this.selectedHour, 59, 59, 999);
      toTime = toTime.toISOString();

      //check selectedDate and time and if it is in future, show snackbar with warning
      var now = new Date();
      var fromTimeDate = new Date(fromTime);
      if (fromTimeDate > now) {
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t(
          "ReporDriverRoutes.msgDateTimeInFuture"
        );
        this.snackbar.color = "warning";
        this.loadingDataUpdate = false;
        this.loadingDataPlus = false;
        this.loadingDataMinus = false;
        return;
      }

      this.loadingData = true;
      this.clearDriverMarkers();
      // Example: GET /dispatching/driver/{driverId}/position?from=YYYY-MM-DDTHH:mm:ss&to=...
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/dispatching/driver/${this.driverId}/position?from=${fromTime}&to=${toTime}`;
      axios
        .get(url)
        .then((res) => {
          var positionList = res.data || [];
          //snackbar ze sa nenasli ziadne zaznamy
          if (positionList.length == 0) {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("ReporDriverRoutes.msgNoDataFound");
            this.snackbar.color = "warning";
          }

          positionList.sort((a, b) =>
            a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
          );
          //dopln poradove cislo objednavky (orderIndex) podľa orderId, ak sa orderId opakuje, tak ma rovnaky orderIndex
          var orderIndex = 0;
          var orderId = null;
          positionList.forEach((positionObject, index) => {
            //Prvy zaznam
            if (index == 0) {
              positionObject.mark = "START_MARKER";
            }
            if (positionObject.orderId) {
              if (positionObject.orderId != orderId) {
                orderId = positionObject.orderId;
                orderIndex++;
                positionObject.orderIndex = orderIndex;
                positionObject.markOrder = "START_ORDER";
              } else {
                positionObject.orderIndex = orderIndex;
              }
            }
            //Posledny zaznam
            if (index == positionList.length - 1) {
              positionObject.mark = "END_MARKER";
            }
            //ak je medzi polohami createdAt dlhsie ako 2 minuty, oznacit mark ako POSITION_MISSING
            if (index > 0) {
              var createdAt = new Date(positionObject.createdAt);
              var createdAtPrev = new Date(positionList[index - 1].createdAt);
              var diff = Math.abs(createdAt - createdAtPrev) / 1000;
              if (diff > 120) {
                positionList[index - 1].mark = "POSITION_MISSING";
              }
            }
          });

          //if more than 250 positions, reduce positions
          if (positionList.length > 250 && this.reducePositions == true) {
            var positionListReduced = [];
            positionList.forEach(function (positionObject, index) {
              if (index % Math.round(positionList.length / 100, 0) == 0) {
                positionListReduced.push(positionObject);
              }
            });
            positionList = positionListReduced;
          }
          this.positions = positionList;
          this.displayPositions();
        })
        .catch((e) => {
          this.loadingData = false;
          this.loadingDataUpdate = false;
          this.loadingDataPlus = false;
          this.loadingDataMinus = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },

    getStatusColor(status) {
      return this.StatusColor[status];
    },

    displayPositions() {
      // Clear previous polyline if it exists
      if (this.driverPolyline) {
        this.driverPolyline.setMap(null);
        this.driverPolyline = null;
      }
      if (!this.map) {
        this.loadingData = false;
        this.loadingDataUpdate = false;
        this.loadingDataPlus = false;
        this.loadingDataMinus = false;
        return;
      }
      let pathCoords = [];
      this.positions.forEach((pos, index) => {
        setTimeout(() => {
          const latLng = new google.maps.LatLng(pos.latitude, pos.longitude);
          // Determine marker color
          let markerColor = "green";
          if (pos.orderId && pos.orderStatus) {
            switch (pos.orderStatus) {
              case "NEW":
              case "PREREGISTERED":
              case "TRANSFERED":
              case "PLANNED":
                markerColor = "#673AB7";
                break;
              case "ACCEPTED":
                markerColor = "#FBC02D";
                break;
              case "WAITING":
                markerColor = "#FF5722";
                break;
              case "IN_PROGRESS":
                markerColor = "#1565C0";
                break;
              case "FINISHED":
              case "CHARGED":
              case "SETTLED":
                markerColor = "#0277BD";
                break;
              default:
                markerColor = "#388E3C";
            }
          }
          if (pos.mark == "POSITION_MISSING") {
            markerColor = "#D32F2F";
          } else if (pos.mark == "START_MARKER") {
            markerColor = "#BBBBBB";
          } else if (pos.mark == "END_MARKER") {
            markerColor = "#222222";
          } else if (pos.markOrder == "START_ORDER") {
            //markerColor = "#FFAA00";
          }
          // old marker creation using google.maps.Marker
          /* let marker = new google.maps.Marker({
          map: this.map,
          position: latLng,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: markerColor,
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 6,
          },
          title: `Latitude: ${pos.latitude}, Longitude: ${
            pos.longitude
          }, RecordedAt UTC: ${pos.recordedAt}, OrderId: ${pos.orderId || "none"},
           OrderStatus: ${pos.orderStatus || "none"}`,
        }); */
          // New marker creation using google.maps.marker.AdvancedMarkerElement
          const marker = new google.maps.marker.AdvancedMarkerElement({
            map: this.map,
            position: latLng,
            //Latitude: ${pos.latitude}, Longitude: ${pos.longitude},
            title: `Date UTC: ${pos.createdAt}, 
            ${pos.orderIndex ? " Order: " + pos.orderIndex + ". " : ""}  
            ${pos.orderId || ""} 
            ${pos.orderStatus ? ", Status: " + pos.orderStatus : ""}`,
            content: this.buildMarker(markerColor, pos),
          });
          this.driverMarkers.push(marker);
          pathCoords.push(latLng);
        }, index * this.markerDelay);
      });
      // Fit map to all markers.
      if (pathCoords.length > 1) {
        this.map.fitBounds(this.createBounds(pathCoords));
      }

      // Group positions to create blue path lines when orderId is present, green otherwise.
      let groupedPaths = [];
      let currentSegment = [];
      let currentOrderId =
        this.positions.length > 0 ? this.positions[0].orderId : null;
      let currentOrderIndex =
        this.positions.length > 0 ? this.positions[0].orderIndex : null;
      this.positions.forEach((pos, index) => {
        const latLng = new google.maps.LatLng(pos.latitude, pos.longitude);
        if (index === 0) {
          currentSegment.push(latLng);
        } else {
          if (pos.orderId === currentOrderId) {
            currentSegment.push(latLng);
          } else {
            groupedPaths.push({
              path: currentSegment,
              orderId: currentOrderId,
              orderIndex: currentOrderId ? currentOrderIndex : null,
            });
            currentSegment = [latLng];
            currentOrderId = pos.orderId;
            currentOrderIndex = pos.orderId ? pos.orderIndex : null;
          }
        }
        pathCoords.push(latLng);
      });
      // Fit map to all markers.
      if (pathCoords.length > 1) {
        this.map.fitBounds(this.createBounds(pathCoords));
      }
      if (currentSegment.length > 0) {
        groupedPaths.push({
          path: currentSegment,
          orderId: currentOrderId,
          orderIndex: currentOrderId ? currentOrderIndex : null,
        });
      }

      // Draw polyline for each grouped segment and store reference.
      groupedPaths.forEach((group) => {
        let polyColor = "green";
        if (group.orderId) {
          const index = parseInt(group.orderIndex);
          const colorMap = {
            1: "#0D47EB",
            2: "#1565E0",
            3: "#1946A2",
            4: "#2E88FF",
            5: "#2196F3",
            6: "#42A5F5",
          };
          polyColor = index >= 1 && index <= 6 ? colorMap[index] : "blue";
        }
        const polyline = new google.maps.Polyline({
          path: group.path,
          strokeColor: polyColor,
          strokeWeight: 3,
          map: this.map,
        });
        this.driverPolylines.push(polyline);
      });

      // Reset loading flags after delay.
      setTimeout(() => {
        this.loadingData = false;
        this.loadingDataUpdate = false;
        this.loadingDataPlus = false;
        this.loadingDataMinus = false;
      }, 1000);
    },

    buildMarker(color, pos) {
      // Simple snippet returning marker HTML with orderIndex included in title
      //if mark or markOrder is set, then double the size of the marker
      const div = document.createElement("div");
      div.style.borderRadius = "50%";
      div.style.width = pos.mark || pos.markOrder ? "32px" : "16px";
      div.style.height = pos.mark || pos.markOrder ? "32px" : "16px";
      div.style.backgroundColor = color;
      // center align text within the glyph
      div.style.display = "flex";
      div.style.alignItems = "center";
      div.style.justifyContent = "center";
      // add orderIndex text if available
      if (pos.orderIndex !== undefined && pos.orderIndex !== null) {
        const span = document.createElement("span");
        span.textContent = pos.orderIndex || "";
        span.style.color = "#fff";
        span.style.fontSize = pos.mark || pos.markOrder ? "16px" : "10px";
        div.appendChild(span);
      }
      div.title = `Date UTC: ${pos.createdAt}, 
            ${pos.orderIndex ? " Order: " + pos.orderIndex + ". " : ""}  
            ${pos.orderId || ""} 
            ${pos.orderStatus ? ", Status: " + pos.orderStatus : ""}`;
      return div;
    },
    createBounds(coords) {
      const bounds = new google.maps.LatLngBounds();
      coords.forEach((c) => bounds.extend(c));
      return bounds;
    },

    clearDriverMarkers() {
      this.driverMarkers.forEach((marker) => marker.setMap(null));
      this.driverMarkers = [];
      if (this.driverPolyline) {
        this.driverPolyline.setMap(null);
        this.driverPolyline = null;
      }
      // Clear all grouped polylines
      this.driverPolylines.forEach((pline) => pline.setMap(null));
      this.driverPolylines = [];
    },

    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    selectHour(hour) {
      this.selectedHour = hour;
      this.getAll();
    },
    selectHourFromPopup(hour) {
      this.selectedHour = hour;
      this.hourMenu = false;
      this.getAll();
    },
  },
};
</script>
