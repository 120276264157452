<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title> {{ $t("ReportOrderSentLog.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-arrow-right-bold-box-outline</v-icon>
    </v-toolbar>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="10">
        <datetime-range-picker filled v-model="dateRange" eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" @click="getAll()">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      :search="filter.search"
      class="elevation-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, 250, -1],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:[`body.prepend`]>
        <tr>
          <td></td>
          <td></td>
          <!--<td>
            <v-text-field
              v-model="filter.id"
              type="text"
              label="ID"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.taxiserviceId"
              type="number"
              label="txId"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>-->
          <td>
            <v-text-field
              v-model="filter.fromBy"
              :label="$t('ReportOrderSentLog.fromBy')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.distance"
              :label="$t('ReportOrderSentLog.distance')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.driverId"
              :label="$t('ReportOrderSentLog.driverId')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.driverFirstName"
              :label="$t('ReportOrderSentLog.driverFirstName')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.driverLastName"
              :label="$t('ReportOrderSentLog.driverLastName')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.orderId"
              :label="$t('ReportOrderSentLog.orderId')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <!-- <template v-slot:[`item.actions`]="{ item }"> -->
        <!-- button to locate IP address on external service https://www.iplocation.net/?submit=IP+Lookup&query=  to open on new tab -->
        <!-- <v-btn
          :href="`https://www.iplocation.net/?submit=IP+Lookup&query=${item.ipAddress}`"
          target="_blank"
          icon
          class="ml-2"
        >
          <v-icon
            small
            :title="$t('ReportCallLog.btnFindIpAddress')"
            >mdi-map-marker</v-icon
          >
        </v-btn> -->
      <!-- </template> -->

      <template v-slot:[`item.createdAt`]="{ item }">
        {{
          item.createdAt ? $options.filters.formatDateLong(item.createdAt) : "-"
        }}
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

export default {
  data() {
    return {
      moment: moment,
      //Nastavenia modulov
      module: moduleDefaultSettings,
      gridItems: [],
      loadingData: false,
      dateRange: [], //["2021-07-22","2021-07-24"],
      displayOnlyForAdmin: false,
      sortBy: "createdAt",
      sortDesc: true,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        id: "",
        taxiserviceId: null,
        orderId: "",
        sentStatus: "",
        fromBy: "",
        distance: "",
        driverFirstName: "",
        driverLastName: "",
        driverId: "",
        createdAt: "",
        message: "",
      },

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },
  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
  },

  mounted() {
    this.getUserRole();
    //Niekedy pri prvom nacitani stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },
  computed: {
    gridHeaders() {
      return [
        {
          text: this.$t("ReportOrderSentLog.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("ReportOrderSentLog.createdAt"),
          value: "createdAt",
          sortable: true,
        },
        /*{
          text: "ID",
          value: "id",
          filter: (value) => {
            if (!this.filter.id) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.id.toLowerCase());
            }
          },
        },
        {
          text: "taxiserviceId",
          value: "taxiserviceId",
          filter: (value) => {
            if (!this.filter.taxiserviceId) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.taxiserviceId.toLowerCase());
            }
          },
        },*/
        {
          text: this.$t("ReportOrderSentLog.fromBy"),
          value: "fromBy",
          filter: (value) => {
            if (!this.filter.fromBy) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.fromBy.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderSentLog.distance"),
          value: "distance",
          filter: (value) => {
            if (!this.filter.distance) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.distance.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderSentLog.driverId"),
          value: "driverId",
          filter: (value) => {
            if (!this.filter.driverId) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.driverId.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderSentLog.driverFirstName"),
          value: "driverFirstName",
          filter: (value) => {
            if (!this.filter.driverFirstName) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.driverFirstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderSentLog.driverLastName"),
          value: "driverLastName",
          filter: (value) => {
            if (!this.filter.driverLastName) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.driverLastName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("ReportOrderSentLog.orderId"),
          value: "orderId",
          filter: (value) => {
            if (!this.filter.orderId) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.orderId.toLowerCase());
            }
          },
        },
      ];
    },
  },
  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getAll() {
      //Ak nie je povoleny modul s reportmi, nenaciata ziadne data
      if (this.module.report == false) {
        this.gridItems = [];
        //this.$router.push({ name: "dashboard" });
        //snackbar
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgModuleReportNotEnabled");
        this.snackbar.color = "error";
        return;
      }
      if (this.checkDateRange() == false) {
        return;
      }
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/order-sent-log?from=${this.dateRange[0]}&to=${this.dateRange[1]}`
        )
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    checkDateRange() {
      //dateRange moze byt iba v rozsahu 2 mesiacov
      if (this.dateRange[0] == null || this.dateRange[1] == null) {
        alert("Zadajte rozsah dátumov");
        return false;
      } else {
        var dateFrom = new Date(this.dateRange[0]);
        var dateTo = new Date(this.dateRange[1]);
        var diffTime = Math.abs(dateTo - dateFrom);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log("diffDays", diffDays);
        if (diffDays > 63) {
          alert("Rozsah dátumov môže byť maximálne 2 mesiace");
          return false;
        } else {
          return true;
        }
      }
    },
    /* detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },*/
  },
};
</script>
