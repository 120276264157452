<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("OrderListView.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-road-variant</v-icon>
    </v-toolbar>
    <!--    <v-row>
      <v-col cols="12" sm="6">
        <v-date-picker v-model="dates" range></v-date-picker>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="dateRangeText"
          label="Date range"
          prepend-icon="mdi-calendar"
          readonly
        ></v-text-field>
        model: {{ dates }}
      </v-col>
    </v-row>-->
    <!--<v-row class="d-flex align-end flex-column">-->
    <v-row>
      <v-col
        v-if="$vuetify.breakpoint.smAndDown"
        cols="12"
        md="3"
        class="d-flex"
      >
        <v-switch
          v-model="mobileBreakpointSwitch"
          :label="$t('OrderListView.mobileBreakpointSwitch_label')"
          hide-details
        ></v-switch>
      </v-col>
      <v-col cols="12" md="3" class="d-flex">
        <v-switch
          v-model="marketApi"
          :label="$t('OrderListView.marketApi_label')"
          hide-details
        ></v-switch>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4">
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          :label="$t('OrderListView.selectedHeaders_label')"
          multiple
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 3" small>
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 3" class="grey--text caption"
              >(+{{ selectedHeaders.length - 2 }})</span
            >
          </template>
        </v-select></v-col
      >
    </v-row>
    <v-row class="my-1" align="center">
      <v-col cols="12" md="2">
        <v-switch
          v-model="onlyRealized"
          :label="$t('OrderListView.onlyRealized_label')"
          class="my-0"
          hide-details
          dense
        ></v-switch>
      </v-col>
      <v-col cols="12" md="8">
        <datetime-range-picker filled v-model="dateRange" eager>
        </datetime-range-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="info" @click="getAll()" :disabled="loadingData">
          <v-icon left dark> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="showHeaders"
      :items="gridItems"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 250, 500, -1],
      }"
      class="elevation-1"
      :search="filter.search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      dense
      :mobile-breakpoint="mobileBreakpointTable"
    >
      <!-- <template v-slot:header.id="{ header }">
        {{ header.text }}
        <v-text-field
          v-model="filter.id"
          type="text"
          append-icon="mdi-magnify"
          label="Filter"
          clearable
          hide-details
        ></v-text-field>
      </template> -->

      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td :hidden="actionsHidden">
            <v-icon>mdi-filter</v-icon>
          </td>
          <td :hidden="idHidden">
            <v-text-field
              v-model="filter.id"
              type="text"
              clearable
              :label="$t('OrderListView.filterLabel.Id')"
            ></v-text-field>
          </td>
          <td :hidden="statusHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Status')"
              single-line
              clearable
              v-model="filter.status"
              :items="Object.values($t('orderStatus'))"
            ></v-select>
          </td>
          <td :hidden="typeHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Type')"
              clearable
              v-model="filter.type"
              :items="Object.values($t('orderType'))"
            ></v-select>
          </td>
          <td :hidden="sourceHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Source')"
              clearable
              v-model="filter.source"
              :items="Object.values($t('source'))"
            ></v-select>
          </td>
          <td :hidden="paymentTypeHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.PaymentType')"
              clearable
              v-model="filter.paymentType"
              :items="Object.values($t('paymentType'))"
            ></v-select>
          </td>
          <td :hidden="createdAtHidden">
            <v-text-field
              v-model="filter.createdAt"
              type="text"
              clearable
              :label="$t('OrderListView.filterLabel.CreatedAt')"
            ></v-text-field>
          </td>
          <td :hidden="acceptedAtHidden">
            <v-text-field
              v-model="filter.acceptedAt"
              type="text"
              clearable
              :label="$t('OrderListView.filterLabel.AcceptedAt')"
            ></v-text-field>
          </td>
          <td :hidden="driverHidden">
            <!-- <v-text-field
              v-model="filter.driverId"
              type="number"
              clearable
              :label="$t('OrderListView.filterLabel.DriverId')"
            ></v-text-field> -->
            <v-text-field
              v-model="filter.driverName"
              type="text"
              clearable
              :label="$t('OrderListView.filterLabel.DriverName')"
            ></v-text-field>
          </td>
          <td :hidden="taxiserviceNameHidden">
            <v-text-field
              v-model="filter.taxiserviceName"
              type="text"
              clearable
              :label="$t('OrderListView.filterLabel.taxiservice')"
            ></v-text-field>
          </td>
          <td :hidden="providerTaxiserviceNameHidden">
            <v-text-field
              v-model="filter.providerTaxiserviceName"
              type="text"
              clearable
              :label="$t('OrderListView.filterLabel.ProviderTaxiservice')"
            ></v-text-field>
          </td>
          <td :hidden="boardAddressHidden">
            <v-text-field
              v-model="filter.boardAddress"
              type="text"
              clearable
              :label="$t('OrderListView.filterLabel.boardAddress')"
            ></v-text-field>
          </td>
          <td :hidden="destinationAddressHidden">
            <v-text-field
              v-model="filter.destinationAddress"
              type="text"
              clearable
              :label="$t('OrderListView.filterLabel.destinationAddress')"
            ></v-text-field>
          </td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6" :hidden="actionsHidden"></v-col>
          <v-col cols="12" sm="6" :hidden="idHidden">
            <v-text-field
              v-model="filter.id"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.Id')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="statusHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Status')"
              single-line
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.status"
              :items="Object.values($t('orderStatus'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="typeHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Type')"
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.type"
              :items="Object.values($t('orderType'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="sourceHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.Source')"
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.source"
              :items="Object.values($t('source'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="paymentTypeHidden">
            <v-select
              :label="$t('OrderListView.filterLabel.PaymentType')"
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.paymentType"
              :items="Object.values($t('paymentType'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="createdAtHidden">
            <v-text-field
              v-model="filter.createdAt"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.CreatedAt')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="acceptedAtHidden">
            <v-text-field
              v-model="filter.acceptedAt"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.AcceptedAt')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="driverHidden">
            <!-- <v-text-field
              v-model="filter.driverId"
              type="number"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.DriverId')"
              dense
              hide-details
            ></v-text-field> -->
            <v-text-field
              v-model="filter.driverName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.DriverName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="taxiserviceNameHidden">
            <v-text-field
              v-model="filter.taxiserviceName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.taxiservice')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="providerTaxiserviceNameHidden">
            <v-text-field
              v-model="filter.providerTaxiserviceName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.ProviderTaxiservice')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="boardAddressHidden">
            <v-text-field
              v-model="filter.boardAddress"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.boardAddress')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="destinationAddressHidden">
            <v-text-field
              v-model="filter.destinationAddress"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('OrderListView.filterLabel.destinationAddress')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{
          item.createdAt ? $options.filters.formatDateLong(item.createdAt) : "-"
        }}
      </template>

      <template v-slot:[`item.acceptedAt`]="{ item }">
        {{
          item.acceptedAt
            ? $options.filters.formatDateLong(item.acceptedAt)
            : "-"
        }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getStatusColor(item.status)"
          :class="
            getStatusColorDark(item.status) == true
              ? 'ma-1 pa-1 white--text'
              : 'ma-1 pa-1 black--text'
          "
          label
          small
          :title="$t('orderStatus_tooltip.' + item.status)"
        >
          {{ $t("orderStatus." + item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          :title="$t('orderType_tooltip.' + item.type)"
          :color="getTypeColor(item.type)"
          dark
          label
          small
        >
          {{ $t("orderType." + item.type) }}
        </v-chip>
      </template>

      <template v-slot:[`item.paymentType`]="{ item }">
        <v-chip
          :title="$t('paymentType_tooltip.' + item.paymentType)"
          :color="getPaymentTypeColor(item.paymentType)"
          dark
          label
          small
        >
          {{ $t("paymentType." + item.paymentType) }}
        </v-chip>
      </template>

      <template v-slot:[`item.source`]="{ item }">
        <v-chip
          :title="$t('source_tooltip.' + item.source)"
          :color="SourceColor[item.source]"
          dark
          label
          small
        >
          {{ $t("source." + item.source) }}
        </v-chip>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <span
          :title="$t('OrderListView.Id_tooltip', { msg: item.id })"
          :class="item.teamId ? 'red--text font-weight-bold' : ''"
          dark
          label
        >
          {{ item.id.slice(0, 8) }}
        </span>
      </template>

      <template v-slot:[`item.destinationAddress`]="{ item }">
        <span dark label class="text-wrap">
          {{ item.destinationAddress }}
        </span>
      </template>

      <template v-slot:[`item.boardAddress`]="{ item }">
        <span dark label class="text-wrap">
          {{ item.boardAddress }}
        </span>
      </template>

      <template v-slot:[`item.driver`]="{ item }">
        <span dark label>
          {{
            item.driver.internalName
              ? item.driver.internalName + " - "
              : item.driver.id + " - "
          }}
          {{ item.driver ? item.driver.firstName : "" }}
          {{ item.driver ? item.driver.lastName : "" }}
        </span>
      </template>
      <template v-slot:[`item.taxiserviceName`]="{ item }">
        <span dark label>
          {{
            item.taxiserviceId
              ? item.taxiserviceId
              : ""
          }}
          {{ item.taxiserviceName ? item.taxiserviceName
              : "" }}
        </span>
      </template>
      <template v-slot:[`item.providerTaxiserviceName`]="{ item }">
        <span dark label>
          {{
            item.providerTaxiserviceId
              ? item.providerTaxiserviceId
              : ""
          }}
          {{ item.providerTaxiserviceName ? item.providerTaxiserviceName
              : "" }}
        </span>
      </template>
      <template v-slot:[`item.finalPriceTotal`]="{ item }">
        <span
          :class="
            item.finalPriceTotal != item.estimatedPriceTotal
              ? 'font-weight-bold'
              : ''
          "
        >
          {{ item.finalPriceTotal }}
        </span>
      </template>
      <template v-slot:[`item.finalPriceExtra`]="{ item }">
        <span :class="item.finalPriceExtra != 0 ? 'font-weight-bold' : ''">
          {{ item.finalPriceExtra }}
        </span>
      </template>
      <template v-slot:[`item.finalPriceDiscount`]="{ item }">
        <span :class="item.finalPriceDiscount != 0 ? 'font-weight-bold' : ''">
          {{ item.finalPriceDiscount }}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click="detail(item.id)"
          @contextmenu.prevent="openInNewTab(item.id)"
          >fa-eye</v-icon
        >
        <v-icon small @click="previewItem(item.id)"
          >mdi-information-variant</v-icon
        >
      </template>
    </v-data-table>
    <v-spacer></v-spacer>
    <br />
    <!--<download-excel
      :data="gridItems"
      worksheet="txm_export"
      name="txm_expor.xls"
      ><v-btn color="info" outlined> Download Data</v-btn>
    </download-excel>-->
    <v-row dense>
      <v-col cols="12">
        <export-data
          :jsonData="gridItems"
          :jsonFields="jsonFields"
          :outlined="true"
          dense
        >
        </export-data>
        <!-- Prestali sme v API zo servera kvoli uspore dat posielat GPS polohy adriet, preto zobrazenie heatmap nemoze fungovat -->
        <!-- <v-btn
          color="warning"
          @click="showBoardMapDialog()"
          :title="$t('OrderListView.btnVizualizeAddresses_title')"
          class="mx-1"
          outlined
        >
          <v-icon left dark> fas fa-street-view </v-icon>
          {{ $t("OrderListView.btnVizualizeAddresses") }}
        </v-btn> -->
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <!--<v-dialog v-model="dialog" width="90%">
      <v-btn color="primary" dark slot="activator">Open Dialog</v-btn>
      <v-card height="80vh">
        <iframe style="width: 100%; height: 95%" :src="dialogUrl"></iframe>
      </v-card>
    </v-dialog>-->
    <v-dialog v-model="mapDialog" eager max-width="90vw">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("OrderListView.vizualizeAddressOnMapDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <orders-google-map
                :passDataOnMap="passDataOnMap"
                mapHeight="70vh"
                :showBoardMarkers="showBoardMarkers"
                :showDestinationMarkers="showDestinationMarkers"
                :showOrderPath="showOrderPath"
                :showBoardHeatmap="showBoardHeatmap"
                :showDestinationHeatmap="showDestinationHeatmap"
              ></orders-google-map
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn color="primary" text @click="mapDialog = false">{{
            $t("btnClose")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog s detailom objednavky -->
    <v-dialog v-model="detailDialog" eager max-width="90vw">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("OrderListView.detailDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <template>
                <v-chip dark label class="ma-1 pa-1">
                  {{ item.id }}
                </v-chip>
              </template>
              <template>
                <v-chip
                  dark
                  :color="getSourceColor(item.source)"
                  class="ma-1 pa-1"
                  label
                  v-show="item.source != ''"
                >
                  {{ $t("source." + item.source) }}
                </v-chip>
              </template>
              <template>
                <v-chip
                  dark
                  :color="getTypeColor(item.type)"
                  class="ma-1 pa-1"
                  label
                  :title="$t('orderType_tooltip.' + item.type)"
                  v-show="item.type != ''"
                >
                  {{ $t("orderType." + item.type) }}
                </v-chip>
              </template>
              <v-chip
                dark
                label
                class="ma-1 pa-1"
                :title="
                  $t('ConsoleOrderDetail_tooltip.createdAtChip') +
                  item.createdAt
                "
                v-show="item.createdAt != null"
              >
                {{ item.createdAt | formatDateLong }}
              </v-chip>
              <template>
                <v-chip
                  :class="
                    getStatusColorDark(item.status) == true
                      ? 'ma-1 pa-1 white--text'
                      : 'ma-1 pa-1 black--text'
                  "
                  :color="getStatusColor(item.status)"
                  label
                  v-show="item.status != ''"
                  :title="
                    $t('orderStatus_tooltip.' + item.status) +
                    ' | ' +
                    $t('ConsoleOrderDetail_tooltip.changeStatusAtChip')
                  "
                >
                  {{ $t("orderStatus." + item.status) }}
                </v-chip>
              </template>
              <template>
                <v-chip
                  :color="getPaymentTypeColor(item.paymentType)"
                  class="ma-1 pa-1"
                  label
                  dark
                  v-show="item.paymentType != ''"
                  :title="$t('paymentType_tooltip.' + item.paymentType)"
                >
                  {{ $t("paymentType." + item.paymentType) }}
                </v-chip>
              </template>
            </v-col>
          </v-row>
          <v-row v-if="item.account" dense>
            <v-col cols="12">
              <span>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.account')"
                  >mdi-briefcase-variant</v-icon
                >
                {{ $t("ConsoleOrderDetail.account") }}
                {{ item.account.id }} -
                {{ item.account.name }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="item.client" dense>
            <v-col cols="12">
              <v-icon :title="$t('ConsoleOrderDetail_tooltip.client')"
                >mdi-human-greeting</v-icon
              >
              {{ !item.client ? $t("ConsoleOrderDetail.clientNone") : "" }}
              {{
                item.client
                  ? !item.client.id
                    ? $t("ConsoleOrderDetail.clientNone")
                    : ""
                  : ""
              }}

              {{
                item.client
                  ? item.client.nickname
                    ? item.client.nickname
                    : ""
                  : ""
              }}

              {{
                item.client
                  ? item.client.mark
                    ? " | " + $t("clientMark." + item.client.mark)
                    : ""
                  : ""
              }}

              {{
                item.client
                  ? item.client.phone
                    ? " | " + item.client.phone
                    : ""
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row v-if="item.driver" dense>
            <v-col cols="12">
              <v-icon :title="$t('ConsoleOrderDetail_tooltip.driver')"
                >fas fa-user-tie</v-icon
              >
              {{ !item.driver ? $t("ConsoleOrderDetail.driverNone") : "" }}
              {{
                item.driver
                  ? item.driver.firstName
                    ? item.driver.firstName
                    : ""
                  : ""
              }}
              {{
                item.driver
                  ? item.driver.lastName
                    ? item.driver.lastName
                    : ""
                  : ""
              }}

              {{ item.vehicle ? (item.vehicle.id ? " | " : "") : "" }}
              {{
                item.vehicle
                  ? item.vehicle.internalName
                    ? item.vehicle.internalName
                    : ""
                  : ""
              }}
              {{
                item.vehicle
                  ? item.vehicle.brand
                    ? item.vehicle.brand
                    : ""
                  : ""
              }}
              {{
                item.vehicle
                  ? item.vehicle.model
                    ? item.vehicle.model
                    : ""
                  : ""
              }}
              {{
                item.preferredDriver
                  ? item.preferredDriver.id
                    ? " | &#9825;"
                    : ""
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row v-if="item.note" dense>
            <v-col cols="12">
              <span>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.note')"
                  >far fa-file-alt</v-icon
                >
                {{ $t("ConsoleOrderDetail.note") }}
                {{ item.note }}
              </span>
            </v-col>
          </v-row>
          <v-divider :inset="false"></v-divider>
          <v-list>
            <template v-for="(waypoint, index2) in item.waypointList">
              <v-icon
                :key="waypoint.id"
                :color="waypoint.status == 'COMPLETED' ? 'green' : 'gray'"
                >{{ addressIcons[waypoint.type] }}</v-icon
              >
              <span
                :key="'W' + waypoint.id"
                class="d-inline-block"
                :style="[
                  waypoint.status == 'DISABLED'
                    ? {
                        'text-decoration': 'line-through',
                      }
                    : waypoint.type == 'BOARD' || waypoint.type == 'DESTINATION'
                    ? { 'font-weight': 'bold' }
                    : {},
                ]"
                >{{ waypoint.address }}</span
              >
              <v-divider
                v-if="index2 < item.waypointList.length - 1"
                :key="index2"
                :inset="false"
              ></v-divider>
            </template>
          </v-list>
          <v-divider :inset="false"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <v-icon :title="$t('ConsoleOrderDetail_tooltip.price')"
                >mdi-cash-multiple</v-icon
              >{{ $t("ConsoleOrderDetail.price") }} :
              {{
                item.estimatedPriceTotal
                  ? $t("ConsoleOrderDetail.priceEstimated") +
                    " " +
                    item.estimatedPriceTotal +
                    (item.currency ? item.currency : " ")
                  : " "
              }}
              {{
                item.finalPriceTotal
                  ? " | " +
                    $t("ConsoleOrderDetail.priceFinal") +
                    " " +
                    item.finalPriceTotal +
                    (item.currency ? item.currency : "") +
                    " (" +
                    (item.finalPriceWaiting
                      ? item.finalPriceWaiting +
                        (item.currency ? item.currency : "") +
                        ", "
                      : "") +
                    (item.finalPriceJourney
                      ? item.finalPriceJourney +
                        (item.currency ? item.currency : "")
                      : "") +
                    (item.finalPriceExtra
                      ? ", " +
                        item.finalPriceExtra +
                        (item.currency ? item.currency : "")
                      : "") +
                    (item.finalPriceDiscount
                      ? ", -" +
                        item.finalPriceDiscount +
                        (item.currency ? item.currency : "")
                      : "") +
                    ")"
                  : " "
              }}
            </v-col>
          </v-row>
          <template v-if="item.orderItemList">
            <v-list v-if="item.orderItemList.length > 0">
              <template v-for="orderItem in item.orderItemList">
                <v-list-item
                  :key="orderItem.id"
                  :class="
                    orderItem.active == true
                      ? 'green--text'
                      : 'text-decoration-line-through'
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon>mdi-cash</v-icon>{{ orderItem.name
                      }}{{
                        orderItem.price
                          ? ": " +
                            orderItem.price +
                            (item.currency ? item.currency : "")
                          : ": 0" + (item.currency ? item.currency : "")
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-divider :inset="false"></v-divider>
            </v-list>
          </template>
          <v-row v-if="item.waitingList.length > 0">
            <v-col>
              <v-icon :title="$t('ConsoleOrderDetail_tooltip.waitingList')"
                >mdi-timer-pause-outline</v-icon
              >
              {{ $t("ConsoleOrderDetail.waitingList") }}
              {{
                item.waitingList.length > 0
                  ? "(" + item.waitingList.length + ")"
                  : ""
              }}
            </v-col>
          </v-row>
          <v-simple-table v-if="item.waitingList.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("ConsoleOrderDetail.tbl_startedAt") }}
                  </th>
                  <th class="text-left">
                    {{ $t("ConsoleOrderDetail.tbl_finishedAt") }}
                  </th>
                  <th class="text-left">
                    {{ $t("ConsoleOrderDetail.tbl_timeMin") }}
                  </th>
                  <th class="text-left">
                    {{ $t("ConsoleOrderDetail.tbl_timeSeconds") }}
                  </th>
                  <th class="text-left">
                    {{ $t("ConsoleOrderDetail.tbl_distanceMeters") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(waiting, index2) in item.waitingList" :key="index2">
                  <td class="text-left">
                    {{
                      waiting.startedAt
                        ? $options.filters.formatDateLong(waiting.startedAt)
                        : ""
                    }}
                  </td>
                  <td class="text-left">
                    {{
                      waiting.finishedAt
                        ? $options.filters.formatDateLong(waiting.finishedAt)
                        : ""
                    }}
                  </td>
                  <td class="text-left">
                    {{ waiting.timeMin ? waiting.timeMin : "" }}
                  </td>
                  <td class="text-left">
                    {{ waiting.time ? waiting.time : "" }}
                  </td>
                  <td class="text-left">
                    {{ waiting.distanceMeters ? waiting.distanceMeters : "-" }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider :inset="false"></v-divider>
          <v-row v-if="item.orderSentLogs.length > 0" class="pt-3">
            <v-col>
              <v-icon :title="$t('ConsoleOrderDetail_tooltip.sentOrderLogList')"
                >mdi-send</v-icon
              >
              {{ $t("ConsoleOrderDetail.sentOrderLogList") }}
              {{
                item.orderSentLogs.length > 0
                  ? "(" + item.orderSentLogs.length + ")"
                  : ""
              }}
            </v-col>
          </v-row>
          <v-simple-table v-if="item.orderSentLogs.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("ConsoleOrderDetail.tbl_driver") }}
                  </th>
                  <th v-if="$vuetify.breakpoint.lgAndUp" class="text-center">
                    {{ $t("ConsoleOrderDetail.tbl_sent") }}
                  </th>
                  <th v-else class="text-center">
                    {{ $t("ConsoleOrderDetail.tbl_sent_short") }}
                  </th>
                  <th v-if="$vuetify.breakpoint.lgAndUp" class="text-center">
                    {{ $t("ConsoleOrderDetail.tbl_distance") }}
                  </th>
                  <th v-else class="text-center">
                    {{ $t("ConsoleOrderDetail.tbl_distance_short") }}
                  </th>
                  <th class="text-left">
                    {{ $t("ConsoleOrderDetail.tbl_time") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in item.orderSentLogs" :key="item.id">
                  <td class="px-0">
                    {{ item.firstName ? item.firstName : "" }}
                    {{ item.lastName ? item.lastName : "" }}
                  </td>
                  <td class="px-0 text-center">
                    {{
                      item.fromBy
                        ? item.fromBy == "automat" || item.fromBy == "Auto"
                          ? item.fromBy
                          : "Operator"
                        : ""
                    }}
                  </td>
                  <td class="px-0 text-center">
                    {{ item.distance ? item.distance + "m" : "" }}
                  </td>
                  <td class="px-0">
                    {{ item.createdAt | formatDateLong }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider :inset="false"></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click.stop="detailDialog = false">{{
            $t("btnClose")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import ExportData from "../components/ExportData.vue";
import {
  Status,
  Type,
  Source,
  PaymentType,
  PaymentTypeColor,
  StatusColor,
  StatusColorDarkContrast,
  TypeColor,
  SourceColor,
} from "../enums/OrderEnum";
import OrdersGoogleMap from "../components/OrdersGoogleMap.vue";

export default {
  components: {
    ExportData,
    OrdersGoogleMap,
  },
  data() {
    return {
      Status,
      Type,
      Source,
      PaymentType,
      PaymentTypeColor,
      StatusColor,
      StatusColorDarkContrast,
      TypeColor,
      SourceColor,
      moment: moment,
      marketApi: false,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      taxiserviceList: [],

      /*dialog: false,
      dialogUrl: "https://vuetifyjs.com",*/
      mapDialog: false,
      passDataOnMap: [],
      showBoardMarkers: false,
      showDestinationMarkers: false,
      showOrderPath: false,
      showBoardHeatmap: true,
      showDestinationHeatmap: false,
      //dialog s detailom objednavky
      detailDialog: false,
      //objekt s datami pre detail objednavky
      item: {
        id: "",
        status: "",
        type: "",
        note: "",
        createdAt: null,
        client: {
          id: null,
        },
        driver: {
          id: null,
        },
        taxiserviceId: "",
        taxiserviceName: "",
        providerTaxiserviceId: "",
        providerTaxiserviceName: "",
        preferredDriver: {
          id: null,
        },
        waypointList: [
          {
            id: null,
          },
        ],
        waitingList: [
          {
            finishedAt: "2023-10-19T17:29:06.191692Z",
            id: 1,
            startedAt: "2023-10-19T17:29:02.208112Z",
            status: "COMPLETED",
            time: 3,
            timeMin: 1,
            timeSeconds: 0,
            distanceMeters: 0,
          },
        ],
        orderSentLogs: [
          {
            orderId: null,
            createdAt: null,
          },
        ],
      },
      addressIcons: {
        BOARD: "fas fa-street-view",
        DESTINATION: "fas fa-flag-checkered",
        TRANSIT: "mdi-transit-connection-variant",
      },
      //Zoznam vodicov pre doplnenie mena k logu z automatu
      driverList: [],
      //ID taxisluzby a dispecera
      dispatcherId: null,
      taxiserviceId: null,

      //premenne pre vyhladavanie/filter. Podla: https://codepen.io/rveruna/pen/gVBJmq
      filter: {
        search: "",
        id: "",
        type: "",
        status: "",
        note: "",
        driverId: "",
        driverName: "",
        taxiserviceId: "",
        taxiserviceName: "",
        providerTaxiserviceId: "",
        providerTaxiserviceName: "",
        boardAddress: "",
        destinationAddress: "",
        createdAt: "",
        acceptedAt: "",
        source: "",
        paymentType: "",
      },

      // gridHeaders: [
      //   {
      //     text: "Id",
      //     align: "start",
      //     sortable: false,
      //     value: "id",
      //   },
      //   { text: this.$t("Status"), value: "status" },
      //   { text: this.$t("Type"), value: "type" },
      //   { text: this.$t("Note"), value: "note" },
      //   {
      //     text: this.$t("CreatedAt"),
      //     value: "createdAt",
      //   },
      // ],
      gridItems: [],
      headers: [],
      selectedHeaders: [],
      defaultHiddenColumns: [
        "type",
        "source",
        "paymentType",
        "taxiserviceName",
        "providerTaxiserviceName",
        "finalPriceExtra",
        "finalPriceDiscount",
      ],
      sortBy: null,
      sortDesc: true,
      slotList: [
        "actions",
        "id",
        "status",
        "type",
        "source",
        "paymentType",
        "createdAt",
        "acceptedAt",
        "driver",
        "taxiserviceName",
        "providerTaxiserviceName",
        "boardAddress",
        "destinationAddress",
        "estimatedPriceTotal",
        "finalPriceTotal",
        "finalPriceExtra",
        "finalPriceDiscount",
      ],
      //nazvy stlpcov v tabulke, aby sa skryvali filtrovacie polia
      actionsHidden: false,
      idHidden: false,
      statusHidden: false,
      typeHidden: false,
      sourceHidden: false,
      paymentTypeHidden: false,
      createdAtHidden: false,
      acceptedAtHidden: false,
      driverHidden: false,
      taxiserviceNameHidden: false,
      providerTaxiserviceNameHidden: false,
      boardAddressHidden: false,
      destinationAddressHidden: false,
      estimatedPriceTotalHidden: false,
      finalPriceTotalHidden: false,
      finalPriceExtraHidden: false,
      finalPriceDiscountHidden: false,

      //Zoznam stlpcov pre export do excelu
      jsonFields: {
        "Číslo objednávky": { field: "id" }, //Číslo objednávky
        Stav: { field: "status" }, //Stav
        Typ: { field: "type" }, //Typ
        "Zdroj objednávky": { field: "source" }, //Zdroj objednávky
        "Spôsob platby": { field: "paymentType" }, //Spôsob platby
        "Vytvorené dňa (UTC)": { field: "createdAt" }, //Vytvorené dňa (UTC)
        "Vytvorené dňa (local)": {
          field: "createdAt",
          type: "datetime",
          //callback: (value) => { return `${moment(value).format("L LTS")}`; },
        }, //Vytvorené dňa (local)
        "Akceptované vodičom dňa (UTC)": {
          field: "acceptedAt",
          //callback: (value) => { return `${moment(value).format("L LTS")}`; },
        }, //Akceptované vodičom (UTC)
        "Akceptované vodičom dňa (local)": {
          field: "acceptedAt",
          type: "datetime",
        }, //Akceptované vodičom dňa (local)
        "Adresa vyzdvihnutia": { field: "boardAddress" }, //Adresa vyzdvihnutia
        "Adresa cieľa cesty": { field: "destinationAddress" }, //Adresa cieľa cesty
        "ID Vodiča": {
          field: "driver.id",
          //callback: (value) => {return `ID - ${value}`; },
        }, //ID Vodiča
        "Meno vodiča": { field: "driver.firstName", type: "string" }, //Meno vodiča
        "Priezvisko vodiča": { field: "driver.lastName" }, //Priezvisko vodiča
        "ID taxislužby": { field: "taxiserviceId" }, //ID taxislužby
        "Názov taxislužby": { field: "taxiserviceName" }, //Názov taxislužby
        "ID poskytovateľa taxislužby": {
          field: "providerTaxiserviceId",
        }, //ID poskytovateľa taxislužby
        "Názov poskytovateľa taxislužby": {
          field: "providerTaxiserviceName",
        }, //Názov poskytovateľa taxislužby
        "Očakávaná cena": { field: "estimatedPriceTotal", type: "number" }, //Očakávaná cena
        "Finálna cena": { field: "finalPriceTotal", type: "number" }, //Finálna cena
        "Finálny príplatok": { field: "finalPriceExtra", type: "number" }, //Finálna cena - extra
        "Finálna zľava": { field: "finalPriceDiscount", type: "number" }, //Finálna cena - zľava
      },

      loadingData: false,
      //DateRangePicker - vyber rozhadu datumov pre zoznam
      dateRange: [], //["2021-07-22","2021-07-24"],
      onlyRealized: true,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
    //Zistenie zoznamu skrytych stlpcov z Cookies
    var tempHiddenColumns = this.getCookie("actualHiddenColumnsOrderList")
      ? JSON.parse(this.getCookie("actualHiddenColumnsOrderList"))
      : [];
    //ak existuje cookie, nacitat ulozenu hodnotu (zoznam stlpcov, ktore sa maju skryt)
    if (tempHiddenColumns.length > 0) {
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !tempHiddenColumns.includes(el.value)
      );
    } else {
      //ak neexistuje cookie, nastavit defaultne hodnoty
      //odznacenie niektorych stlpcov pri uvodnom nacitani z defaultneho nastavenia
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !this.defaultHiddenColumns.includes(el.value)
      );
    }
    this.getTaxiserviceList();
  },

  mounted() {
    //ID prihlaseneho dispecera
    this.dispatcherId = this.$store.state.user.id;
    //console.log("dispatcherId", dispatcherId);
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //console.log("mounted - this.dateRange", this.dateRange);

    var onlyRealized = this.getCookie("onlyRealized");
    if (onlyRealized && onlyRealized != null) {
      this.onlyRealized = onlyRealized.toLowerCase() == "true";
    } else {
      this.onlyRealized = true;
    }
    var marketApi = this.getCookie("marketApi");
    if (marketApi && marketApi != null) {
      this.marketApi = marketApi.toLowerCase() == "true";
    } else {
      this.marketApi = false;
    }
    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }

    /*var hiddenColumns = JSON.parse(this.getCookie("hiddenColumns"));
    if (hiddenColumns && hiddenColumns != null) {
      this.selectedHeaders = this.selectedHeaders.filter(
        (el) => !hiddenColumns.includes(el.value)
      );
    }*/
    /*
    var showHeaders = this.getCookie("showHeaders");
    if (showHeaders && showHeaders != null) {
      this.showHeaders = JSON.parse(showHeaders);
      console.log("this.selectedHeaders", this.showHeaders);
    }*/
    //Niekedy pri prvom nacitani stranky, nie je hned vyrenderovany sub component, tak pocat 2s. Inak to neviem zatial vyriesit.
    if (this.dateRange[0] == undefined) {
      setTimeout(() => {
        this.getAll();
      }, 2000);
    } else {
      this.getAll();
    }
  },

  watch: {
    showHeaders: function () {
      //ak sa zobrazi hlavicka stlpca, zobrazit aj filter
      var tempShowHearders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          tempShowHearders.some(function (e) {
            return e.value == item;
          })
        ) {
          this[item + "Hidden"] = false;
        } else {
          this[item + "Hidden"] = true;
        }
      });
    },

    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  computed: {
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },

    headersMap() {
      return [
        {
          text: this.$t("OrderListView.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("OrderListView.Id"),
          align: "start",
          sortable: false,
          value: "id",
          filter: (value) => {
            if (!this.filter.id) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.id.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.Status"),
          value: "status",
          filter: (value) => {
            if (!this.filter.status) {
              return true;
            }
            // Vytvoríme si pole všetkých stavov, ktoré sú súčasťou "CANCELLED_ALL"
            const cancelledAllStatuses = [
              "CANCELLED_PREREGISTERED",
              "CANCELLED_NEW",
              "CANCELLED_PLANNED",
              "CANCELLED_PENDING",
              "CANCELLED_ACCEPTED",
              "CANCELLED_WAITING",
              "CANCELLED_IN_PROGRESS",
              "CANCELLED_FINISHED",
              "CANCELLED_CHARGED",
              "CANCELLED_SETTLED",
            ];

            if (this.filter.status == this.$t("orderStatus.CANCELLED_ALL")) {
              // Ak je vybraný "CANCELLED_ALL", tak vrátime true, ak status patrí do tohto poľa
              return cancelledAllStatuses.includes(value);
            } else {
              return this.$t("orderStatus." + value).includes(
                this.filter.status
              );
            }
          },
        },
        {
          text: this.$t("OrderListView.Type"),
          value: "type",
          filter: (value) => {
            if (!this.filter.type) {
              return true;
            }
            return this.$t("orderType." + value)
              .toLowerCase()
              .includes(this.filter.type.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.Source"),
          value: "source",
          filter: (value) => {
            if (!this.filter.source) {
              return true;
            }
            return this.$t("source." + value)
              .toLowerCase()
              .includes(this.filter.source.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.PaymentType"),
          value: "paymentType",
          filter: (value) => {
            if (!this.filter.paymentType) {
              return true;
            }
            return this.$t("paymentType." + value)
              .toLowerCase()
              .includes(this.filter.paymentType.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.CreatedAt"),
          value: "createdAt",
          filter: (value) => {
            if (!this.filter.createdAt) {
              return true;
            }
            return value
              .toLowerCase()
              .includes(this.filter.createdAt.toLowerCase());
          },
        },
        {
          text: this.$t("OrderListView.AcceptedAt"),
          value: "acceptedAt",
          filter: (value) => {
            if (!this.filter.acceptedAt) {
              return true;
            }
            return value
              .toLowerCase()
              .includes(this.filter.acceptedAt.toLowerCase());
          },
        },
        /*{
          text: this.$t("OrderListView.DriverId"),
          value: "driver",
          filter: (value) => {
            if (!this.filter.driverId) return true;
            if (value) return value.id === parseInt(this.filter.driverId);
          },
        },*/
        {
          text: this.$t("OrderListView.DriverName"),
          //search in firstName, lastName, id, nickname
          value: "driver",
          filter: (value) => {
            if (!this.filter.driverName) {
              return true;
            } else if (value) {
              return (
                value.id +
                " " +
                value.nickname +
                " " +
                value.internalName +
                " " +
                value.firstName +
                " " +
                value.lastName
              )
                .toLowerCase()
                .includes(this.filter.driverName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.Taxiservice"),
          sortable: true,
          value: "taxiserviceName",
          filter: (value) => {
            if (!this.filter.taxiserviceName) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.taxiserviceName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.ProviderTaxiservice"),
          sortable: true,
          value: "providerTaxiserviceName",
          filter: (value) => {
            if (!this.filter.providerTaxiserviceName) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.providerTaxiserviceName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.boardAddress"),
          sortable: false,
          value: "boardAddress",
          filter: (value) => {
            if (!this.filter.boardAddress) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.boardAddress.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.destinationAddress"),
          sortable: false,
          value: "destinationAddress",
          filter: (value) => {
            if (!this.filter.destinationAddress) {
              return true;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.destinationAddress.toLowerCase());
            }
          },
        },
        {
          text: this.$t("OrderListView.EstimatedPriceTotal"),
          value: "estimatedPriceTotal",
        },
        {
          text: this.$t("OrderListView.FinalPriceTotal"),
          value: "finalPriceTotal",
        },
        {
          text: this.$t("OrderListView.FinalPriceExtra"),
          value: "finalPriceExtra",
        },
        {
          text: this.$t("OrderListView.FinalPriceDiscount"),
          value: "finalPriceDiscount",
        },
      ];
    },
  },

  methods: {
    getTaxiserviceList() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/picker-available`
        )
        .then((response) => {
          this.taxiserviceList = response.data.available;
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    getAll() {
      //console.log("getAll - dateRange ", this.dateRange);
      //Ulozit si nastavenie rozsahu datumov do cookies
      //this.setCookie("dateRange", JSON.stringify(this.dateRange), 1);
      //console.log("getAll - setCookie - dateRange",JSON.stringify(this.dateRange));
      this.setCookie("onlyRealized", this.onlyRealized, 30);
      this.setCookie("marketApi", this.marketApi, 30);
      this.setHiddenColumnListCookie();
      //market API switch
      var marketApiText = this.marketApi ? "/market" : "";
      var taxiserviceList = this.taxiserviceList;
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/order` +
            marketApiText +
            `?from=${this.dateRange[0]}&to=${this.dateRange[1]}&onlyRealized=${this.onlyRealized}`
        )
        .then((response) => {
          //console.log("response", response);
          this.loadingData = false;
          var tempResponseData = response.data;
          //Doplnenie adresy vyzdvihnutia a ciela do zakladneho zoznamu, lebo inak je o uroven nizsie a neda sa to v tabulke zobrazit
          var tempModifiedData = [];
          //console.log("tempResponseData", tempResponseData);
          //console.log("tempModifiedData", tempModifiedData);
          //Ak sú zobrazené iba zrealizované objednávky, usporiadať od najnovšieho po najstarší podľa acceptedAt
          if (this.onlyRealized) {
            tempResponseData.sort(function (a, b) {
              return new Date(b.acceptedAt) - new Date(a.acceptedAt);
            });
          }
          var transit = false;
          tempResponseData.forEach(function (item) {
            transit = false;
            if (item.waypointList) {
              item.waypointList.forEach(function (waypointValue) {
                if (
                  waypointValue.type == "BOARD" &&
                  waypointValue.status != "DISABLED"
                ) {
                  item.boardAddress = waypointValue.address;
                } else if (
                  waypointValue.type == "DESTINATION" &&
                  waypointValue.status != "DISABLED"
                ) {
                  item.destinationAddress = waypointValue.address;
                }
                if (
                  waypointValue.type == "TRANSIT" &&
                  waypointValue.status != "DISABLED"
                ) {
                  transit = true;
                }
              });
              if (transit) {
                item.destinationAddress = item.destinationAddress + " (+)";
              }
              //if providerTaxiserviceId is not null, add new parameter providerTaxiserviceName. providerTaxiserviceName can be found in this.taxiserviceList with structure .id, .name, else add empty string
              if (item.providerTaxiserviceId) {
                var tempTaxiservice = taxiserviceList.find(
                  (taxiservice) => taxiservice.id == item.providerTaxiserviceId
                );
                item.providerTaxiserviceName = tempTaxiservice
                  ? tempTaxiservice.name
                  : "";
              } else {
                item.providerTaxiserviceName = "";
              }
              if (item.taxiserviceId) {
                var tempTaxiservice = taxiserviceList.find(
                  (taxiservice) => taxiservice.id == item.taxiserviceId
                );
                item.taxiserviceName = tempTaxiservice
                  ? tempTaxiservice.name
                  : "";
              } else {
                item.taxiserviceName = "";
              }
            }
            tempModifiedData.push(item);
          });
          this.gridItems = tempModifiedData;
          console.log("gridItems", this.gridItems);
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },

    previewItem(id) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/order/` +
            id +
            `/history`
        )
        .then((response) => {
          var tempItem = response.data;
          if (this.driverList.length == 0) {
            this.getDriverList();
          }
          if (tempItem.orderSentLogs) {
            //Usporiadat tempItem.orderSentLogs podla datumu od najnovsieho po najstarsie
            tempItem.orderSentLogs.sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            tempItem.orderSentLogs.forEach((orderSentLog) => {
              var driver = this.driverList.find(
                (driver) => driver.id == orderSentLog.driverId
              );
              if (driver) {
                orderSentLog.firstName = driver.firstName;
                orderSentLog.lastName = driver.lastName;
                orderSentLog.internalName = driver.internalName;
              }
              if (orderSentLog.fromBy == "automat") {
                orderSentLog.fromBy = "Auto";
              }
            });
          }
          //ak existuje waitingList, zistit polohu z positionList, ktora casovo nasleduje po spusteni a ukonceni cakania (casy nie su identické)
          //Pre startedAt nastavit startLatitude a startLongitude a pre finishedAt nastavit finishedLatitude finishedLongitude ako prva a posledna poloha v rozsahu cakania (start a finished) z positionList
          if (tempItem.waitingList && tempItem.waitingList.length > 0) {
            if (tempItem.positionList && tempItem.positionList.length > 0) {
              tempItem.waitingList.forEach((waiting) => {
                var tempPositionList = tempItem.positionList.filter(
                  (position) =>
                    new Date(position.createdAt) >=
                      new Date(waiting.startedAt) &&
                    new Date(position.createdAt) <= new Date(waiting.finishedAt)
                );
                if (tempPositionList.length > 1) {
                  waiting.startLatitude = tempPositionList[0].latitude;
                  waiting.startLongitude = tempPositionList[0].longitude;
                  waiting.finishedLatitude =
                    tempPositionList[tempPositionList.length - 1].latitude;
                  waiting.finishedLongitude =
                    tempPositionList[tempPositionList.length - 1].longitude;
                }
                var lat1 = waiting.startLatitude;
                var lon1 = waiting.startLongitude;
                var lat2 = waiting.finishedLatitude;
                var lon2 = waiting.finishedLongitude;
                var R = 6371; // km
                var dLat = ((lat2 - lat1) * Math.PI) / 180;
                var dLon = ((lon2 - lon1) * Math.PI) / 180;
                var a =
                  Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos((lat1 * Math.PI) / 180) *
                    Math.cos((lat2 * Math.PI) / 180) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                var d = R * c * 1000; // m
                waiting.distanceMeters = Math.round(d * 100) / 100;
              });
            }
          }
          this.item = tempItem;
          this.detailDialog = true;
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
          console.log("error", e);
        });
    },

    getDriverList() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/driver/forPicker?all=true`
        )
        .then((res) => {
          //console.log(res);
          this.driverList = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    getStatusColor(status) {
      return this.StatusColor[status];
    },
    getStatusColorDark(status) {
      if (status == undefined) {
        return this.StatusColorDarkContrast["NEW"];
      } else {
        return this.StatusColorDarkContrast[status];
      }
    },
    getTypeColor(type) {
      return this.TypeColor[type];
    },
    getSourceColor(source) {
      return this.SourceColor[source];
    },
    getPaymentTypeColor(type) {
      return this.PaymentTypeColor[type];
    },
    detail(id) {
      this.$router.push({ name: "order-detail", params: { id: id } });
    },
    openInNewTab(id) {
      const url = this.$router.resolve({
        name: "order-detail",
        params: { id: id },
      }).href;
      window.open(url, "_blank");

      /*this.dialogUrl = this.$router.resolve({
        name: "order-detail",
        params: { id: id },
      }).href;
      this.dialog = true;*/
    },
    showBoardMapDialog() {
      this.showBoardHeatmap = true;
      this.showDestinationHeatmap = false;
      this.passDataOnMap = this.gridItems;
      this.mapDialog = true;
    },
    showDestinationMapDialog() {
      this.showBoardHeatmap = false;
      this.showDestinationHeatmap = true;
      this.passDataOnMap = this.gridItems;
      this.mapDialog = true;
    },

    setHiddenColumnListCookie() {
      //aktualny zoznam poli, ktore sa nemaju zobrazit, ulozit do cookies
      var actualHiddenColumns = [];
      //compare slotList and showHeaders
      var tempShowHeaders = this.showHeaders;
      this.slotList.forEach((item) => {
        if (
          !tempShowHeaders.some(function (e) {
            return e.value == item;
          })
        ) {
          actualHiddenColumns.push(item);
        }
      });
      this.setCookie(
        "actualHiddenColumnsOrderList",
        JSON.stringify(actualHiddenColumns),
        30
      );
    },

    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
  },
};
</script>
